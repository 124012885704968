import "../tutorialPage/TutorialPage.less";
import { EPage } from "../../Main";
import TutorialPage from "../tutorialPage/TutorialPage";
import { EBackgroundColor } from "../../components/gridBackground/GridBackground";
import Anims from "../../data/Anims";

interface IProps {}

class TutorialPage1 extends TutorialPage {
  // --------------------------------------------------------------------------- INIT
  constructor(pProps: IProps, pContext: any) {
    super(pProps, pContext);

    this.initState({
      currentSlideIndex: 1,
      nextPage: EPage.TutorialPage3,
      background: EBackgroundColor.GOLD_LIGHT,
      nextBackground: EBackgroundColor.GOLD,
      anim: Anims.tuto2,
      isBackgroundVisible: true
    });

    this.initAnim();
  }

  // --------------------------------------------------------------------------- TRANSITION

  // --------------------------------------------------------------------------- RENDER
}

export default TutorialPage1;
