import "./AlertDialog.less";
import React from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";

type AlertObject = {
  condition?: boolean;
  text: string;
};

interface IProps {
  classNames?: string[];
  alerts?: Array<AlertObject>;
}

// prepare
const { component, log } = prepare("AlertDialog");

/**
 * @name AlertDialog
 */
function AlertDialog(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={component}>
      <div className={`${component}_list`}>
        {props.alerts.map((log, i) => {
          return (
            (log.condition === undefined || log.condition) && (
              <div key={i} className={`${component}_text`}>
                {log.text}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default AlertDialog;
