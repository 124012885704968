import { useEffect } from "react";
import videoFrameService from "../services/videoFrameService";
import windowBoxesService from "../services/WindowBoxesService/WindowBoxesService";
import { BOX_CONTAINER_ID } from "../components/boxRenderer/BoxRenderer";

let needStart = false;
let enabled;

const detectFrame = async (model, videoRef) => {
  try {
    //await new Promise(resolve => setTimeout(resolve, 250)); // simulate mobile performances (slower detection)

    const predictions = await model.detect(videoRef.current);
    windowBoxesService.update(predictions);
  } catch (error) {
    console.log("prediction error", error);
  }

  // Update windows

  if (enabled) {
    requestAnimationFrame(() => {
      detectFrame(model, videoRef);
    });
  }
};

const useBoxRenderer = (model, videoRef, webcamLoaded) => {
  const startPrediction = () => {
    if (!(model && webcamLoaded)) {
      needStart = true;
      return;
    }
    enabled = true;
    detectFrame(model, videoRef);
  };

  const stopPrediction = () => {
    enabled = false;
    needStart = false;
    windowBoxesService.reset();
  };

  useEffect(() => {
    if (model && webcamLoaded) {
      videoFrameService.videoRef = videoRef;
      windowBoxesService.rootElement = document.getElementById(
        BOX_CONTAINER_ID
      );

      if (!enabled && needStart) {
        startPrediction();
      }
    }
  }, [model, webcamLoaded, videoRef]);

  return {
    startPrediction,
    stopPrediction
  };
};

export default useBoxRenderer;
