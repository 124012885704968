import "./MyCalendarPage.less";
import React, { Fragment, createRef, RefObject } from "react";
import { ReactPage } from "../../lib/solidify/react/ReactPage";
import { Helmet } from "react-helmet";
import { prepare } from "../../helpers/prepare";
import LocalStorageHelper from "../../helpers/localStorageHelper";
import FooterMenu from "../../components/footerMenu";
import { gsap } from "gsap";
import popinService from "../../services/popinService";
import WindowPopin from "../../components/windowPopin/WindowPopin";
import { Quad } from "gsap/gsap-core";
import ArrowButton from "../../components/arrowButton/ArrowButton";
import { Router } from "../../lib/solidify/navigation/Router";
import { EPage } from "../../Main";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {}
interface IStates {
  displayFooterMenu: boolean;
}

// Offset to display footer a little before reaching the end of the page
const FOOTER_SCROLL_OFFSET = 40;

// prepare
const { component, log } = prepare("MyCalendarPage");

// get data
const locale = GlobalConfig.instance.data.pages.calendar;
/**
 * @name MyCalendarPage
 */
class MyCalendarPage extends ReactPage<IProps, IStates> {
  // --------------------------------------------------------------------------- INIT

  // get root ref
  protected $root;
  protected $content;

  constructor(pProps: IProps, pContext: any) {
    super(pProps, pContext);

    this.$root = createRef();
    this.$content = createRef();

    // Get collected window data from local storage
    this.state = {
      displayFooterMenu: false
    };
  }

  componentDidMount(): void {
    gsap.set(document.body, {
      position: "initial"
    });
    this.$root.current.addEventListener(
      "scroll",
      this.onScrollHandle.bind(this)
    );

    window.addEventListener("resize", this.onResizeHandle.bind(this));

    if (
      this.$content.current.clientHeight <=
      window.innerHeight + FOOTER_SCROLL_OFFSET
    ) {
      // force footer display if content size is small than screen size (no scroll in this case)
      this.setState({
        displayFooterMenu: true
      });
    }
  }

  componentWillUnmount(): void {
    gsap.set(document.body, {
      position: "fixed"
    });
    this.$root.current.removeEventListener(
      "scroll",
      this.onScrollHandle.bind(this)
    );
    window.removeEventListener("resize", this.onResizeHandle.bind(this));
  }

  // --------------------------------------------------------------------------- HANDLER

  onScrollHandle() {
    this.showFooterOnBottom();
  }

  onResizeHandle() {
    this.showFooterOnBottom();
  }

  // --------------------------------------------------------------------------- ANIMATION

  // show footer menu on scroll to bottom page
  showFooterOnBottom() {
    if (!this.$content.current) {
      return;
    }

    this.setState({
      displayFooterMenu:
        this.$root.current.scrollTop +
          window.innerHeight +
          FOOTER_SCROLL_OFFSET >=
        this.$content.current.clientHeight
    });
  }

  // --------------------------------------------------------------------------- TRANSITION

  /**
   * Action on this page.
   * Check props.action and props.parameters to show proper content.
   */
  action() {
    // Remove if not used
  }

  /**
   * Play in animation.
   * Call complete handler when animation is done.
   */
  protected playInPromiseHandler(pCompleteHandler: () => void) {
    this.$root.current.scroll(
      0,
      this.$content.current.getBoundingClientRect().height
    );
    pCompleteHandler();
  }

  /**
   * Play out animation.
   * Call complete handler when animation is done.
   */
  protected playOutPromiseHandler(pCompleteHandler: () => void) {
    return new Promise(resolve => {
      gsap.set(this.$root.current, { zIndex: 34 });

      gsap.to(this.$root.current, 0.3, {
        backgroundColor: "rgba(0, 0, 0, 0)"
      });

      gsap.fromTo(
        this.$content.current,
        {
          x: 0,
          y: 0
        },
        {
          duration: 1,
          x: this.$content.current.clientWidth,
          y: -this.$content.current.clientHeight,
          rotation: -40,
          ease: Quad.easeInOut,
          onComplete: () => {
            pCompleteHandler?.();
            resolve();
          }
        }
      );
    });
  }

  // --------------------------------------------------------------------------- RENDER

  render() {
    let windowBoxes = [].concat(LocalStorageHelper.getWindows()).reverse();

    let emptyDates = [];

    let emptyNumber = 6 - windowBoxes.length;
    if (emptyNumber <= 0) emptyNumber = 1;
    if (windowBoxes.length >= 6 && windowBoxes.length % 2 == 0) emptyNumber++;
    for (let i = 0; i < emptyNumber; i++) {
      emptyDates.push(i);
    }

    return (
      <div className={component} ref={this.$root}>
        <div className={`${component}_content`} ref={this.$content}>
          {windowBoxes.length > 5 && (
            <Fragment>
              <span className={`${component}_cloud ${component}_cloud-1`} />
              <span className={`${component}_cloud ${component}_cloud-2`} />
            </Fragment>
          )}
          <Helmet>
            <title>{component}</title>
          </Helmet>
          {/* List data from window boxes in local storage */}
          <ul className={`${component}_calendarWrapper`}>
            <span className={`${component}_panel`} />
            <span className={`${component}_border ${component}_border-left`} />
            {emptyDates.map((windowBox, index) => (
              <li
                key={index}
                className={`${component}_calendarItem ${component}_calendarItem-empty`}
                onClick={() => {
                  Router.openURL(
                    Router.generateURL({ page: EPage.CameraPage })
                  );
                }}
              >
                <p className={`${component}_calendarEmptyItem`}>?</p>
              </li>
            ))}
            {windowBoxes.map((windowBox, index) => (
              <li
                className={`${component}_calendarItem`}
                key={windowBox.window.uid}
                onClick={() => {
                  popinService.show(
                    <WindowPopin
                      windowBox={windowBox.window}
                      image={windowBox.image}
                    />
                  );
                }}
                style={{
                  backgroundImage: `url("${windowBox.image}")`
                }}
              >
                <p className={`${component}_calendarItemNum`}>
                  {windowBoxes.length - index}
                </p>
              </li>
            ))}
            <span className={`${component}_border ${component}_border-right`} />
          </ul>

          <div className={`${component}_buildingWrapper`}>
            <img
              src={"public/img/immeubles_fond.png"}
              className={`${component}_buildingBackground`}
            />
            <span className={`${component}_buildingBackgroundColor`} />
            <img
              src={"public/img/immeuble_debut.png"}
              className={`${component}_buildingBottom`}
            />
          </div>
          <div className={`${component}_footer`}>
            <ArrowButton
              isLarge={false}
              angle={90}
              classNames={[]}
              withArrow={true}
            />
            <div className={`${component}_footerContent`}>
              <p className={`${component}_title`}>{locale.title}</p>
              <p className={`${component}_subtitle`}>{locale.subtitle}</p>
              <p className={`${component}_text`}>{locale.text}</p>
            </div>
            <FooterMenu
              linkToCamera={true}
              isVisible={this.state.displayFooterMenu}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MyCalendarPage;
