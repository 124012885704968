import { useEffect, useState } from "react";

const ACCELERATION_TRESHOLD = 0.25; // for iphone 0.17 -> fast movements
const ROTATION_RATE_TRESHOLD = 6.5;

function initDeviceMotionEvent(handler) {
  window.addEventListener("devicemotion", handler, true);
}

function removeDeviceMotionEvent(handler) {
  window.removeEventListener("devicemotion", handler, true);
}

const useDeviceMotion = () => {
  // TODO: add device motion data to state
  const [hasDeviceMotion, setDeviceMotion] = useState(false);
  const [isDeviceMovingFast, setDeviceMovingFast] = useState(false);

  useEffect(() => {
    const handleDeviceMotionEvent = e => {
      setDeviceMotion(true);
      // set average acceleration
      const averageAcceleration = Math.abs(
        (e.acceleration.x + e.acceleration.y + e.acceleration.z) / 3
      );

      // set average rotation rate
      const averageRotationRate = Math.abs(
        (e.rotationRate.alpha + e.rotationRate.beta + e.rotationRate.gamma) / 3
      );

      // set if device moving too fast
      setDeviceMovingFast(
        averageAcceleration >= ACCELERATION_TRESHOLD ||
          averageRotationRate >= ROTATION_RATE_TRESHOLD
      );
    };

    const requestDeviceOrientationPermission = () => {
      DeviceOrientationEvent["requestPermission"]()
        .then(response => {
          if (response === "granted") {
            initDeviceMotionEvent(handleDeviceMotionEvent);
          }
        })
        .catch(console.error);
    };

    // If iOS 13+, request permission prompt (Issue : bit.ly/deviceorientationpermission)
    if (
      window["DeviceOrientationEvent"] &&
      DeviceOrientationEvent["requestPermission"] &&
      typeof DeviceOrientationEvent["requestPermission"] === "function"
    ) {
      document.body.addEventListener("click", () => {
        requestDeviceOrientationPermission();
      });
      // non iOS 13+
    } else {
      initDeviceMotionEvent(handleDeviceMotionEvent);
    }
  }, [hasDeviceMotion]);
  return {
    hasDeviceMotion,
    isDeviceMovingFast
  };
};

export default useDeviceMotion;
