import { GlobalConfig } from "./GlobalConfig";
import path from "path";

const AppConfig = {
  debug: true,
  webcam: true,
  modelPath: path.join(GlobalConfig.instance.base, "/public/model_web"),
  debugVideoPath: path.join(
    GlobalConfig.instance.base,
    "/public/video/test-facade-5.mp4"
  )
};
export default AppConfig;
