import "./Main.less";
import ReactDOM from "react-dom";
import * as React from "react";
import AppView from "./components/appView/AppView";
import { GlobalConfig } from "./data/GlobalConfig";
import { prepare } from "./helpers/prepare";
import { Router } from "./lib/solidify/navigation/Router";
import { customLog } from "./helpers/customLog";
import configureStore from "./store/index";
import { Provider } from "react-redux";
import EnEn from "./data/locales/EnEn";
import FrFr from "./data/locales/FrFr";
import { LocalLanguage } from "./types";

export enum EPage {
  HomePage = "HomePage",
  TutorialPage1 = "TutorialPage1",
  TutorialPage2 = "TutorialPage2",
  TutorialPage3 = "TutorialPage3",
  CameraPage = "CameraPage",
  MyCalendarPage = "MyCalendarPage"
}

// prepare
const { log } = prepare("Main");

// get user local language
const userLocale: string = navigator.language || navigator["userLanguage"];

// get local app data with language from user
const localAppData = userLocale.indexOf("fr") >= 0 ? FrFr : EnEn;

// ----------------------------------------------------------------------------- INJECT DATA

// Inject params into config
GlobalConfig.instance.inject({
  version: require("../package.json").version,
  base: process.env.APP_BASE,
  env: process.env.ENV,
  locale: userLocale.indexOf("fr") >= 0 ? LocalLanguage.FR : LocalLanguage.EN,
  data: localAppData
});

// Add log in console
["env", "version", "base"].map(el => {
  customLog(log, `${el}: ${GlobalConfig.instance[el]}`);
});

// ----------------------------------------------------------------------------- ROUTES

// Init router
// Google analytics is automatically called when page is changing

Router.init(GlobalConfig.instance.base, [
  {
    url: "/",
    page: EPage.HomePage,
    // Use require to load synchronously
    importer: () => require("./pages/homePage")
    // Use import to load asynchronously
    // importer: () => import("./pages/homePage/HomePage")
  },
  {
    url: "/tutoriel-1",
    page: EPage.TutorialPage1,
    importer: () => require("./pages/tutorialPage1")
  },
  {
    url: "/tutoriel-2",
    page: EPage.TutorialPage2,
    importer: () => require("./pages/tutorialPage2")
  },
  {
    url: "/tutoriel-3",
    page: EPage.TutorialPage3,
    importer: () => require("./pages/tutorialPage3")
  },
  {
    url: "/camera",
    page: EPage.CameraPage,
    importer: () => require("./pages/cameraPage")
  },
  {
    url: "/mon-calendrier",
    page: EPage.MyCalendarPage,
    importer: () => require("./pages/myCalendarPage")
  }
]);

// Enable auto link listening
Router.listenLinks();

// ----------------------------------------------------------------------------- START

// React render
ReactDOM.render(
  // TODO: remove store
  <Provider store={configureStore()}>
    <AppView />
  </Provider>,
  document.getElementById("AppContainer")
);
