import { LocaleStruct } from "../LocaleStruct";
import React from "react";

export class FrFr extends LocaleStruct {
  /**
   * Traductions sur le layout
   */
  static layout = {
    title: "Le Calendrier de l'Après",
    description:
      "Expérience web inédite et innovante mixant Réalité augmentée, Social Content & Intelligence artificielle pour vous souhaiter une nouvelle année inspirée."
  };

  /**
   * Traductions dans les sections
   */
  static pages = {
    home: {
      // TODO: add hidden in homepage for SEO
      title: "Le calendrier de l'après"
    },
    notFound: {
      title: "Oups...",
      text: "La page que vous recherchez n'existe pas !"
    },
    tutorial: {
      slides: [
        {
          title: "Scanne le paysage",
          subtitle: "qui t'entoure avec ton mobile"
        },
        {
          title: "Découvre des resolutions",
          subtitle: "en cliquant sur les fenêtres des bâtiments"
        },
        {
          title: "Sélectionne les meilleures",
          subtitle: "pour créer ton calendrier de l'après"
        }
      ]
    },
    camera: {
      scanMessage: {
        scan: "Scanne",
        the: "les",
        windows: "fenetres",
        nearby: "à proximité"
      },
      stabilityMessage: "Stabilise l'appareil"
    },
    about: {
      title: "à propos",
      paragraphs: [
        {
          text:
            "Pour vous souhaiter une nouvelle année inspirée, Cher Ami a conçu <b>le Calendrier de l’après</b>, une expérience web inédite et innovante mixant <b>Réalité Augmentée, Social Content & Intelligence Artificielle.</b>"
        },
        {
          image: "fenetres_1.png",
          text:
            "Avec votre smartphone, ouvrez l’URL de l’expérience, et visez avec l’appareil photo n’importe quelle fenêtre d’une façade d’un immeuble. Vous pourrez alors cliquer sur certaines fenêtres pour découvrir les bonnes résolutions postées sur Twitter près de chez vous. Si une résolution vous plaît, elle sera sauvegardée dans <b>votre calendrier de l’après</b> pour 2020. </br></br>D’un point de vue technique, cette expérience s’appuie sur une association de technologies inédites et complexes. Tout d’abord, nous avons créé et entraîné une intelligence artificielle pour réaliser une détection et une reconnaissance optimale de fenêtres."
        },
        {
          video: "calendrier_fenetres.mp4",
          text:
            "Nous avons ensuite optimisé ce programme pour fonctionner en temps réel, dans une page web et sur des objets multiples. Cela nous a permis d’associer pour chaque fenêtre détectée par l’intelligence artificielle, de petites animations en réalité augmentée présentant les bonnes résolutions publiées sur Twitter pour 2020."
        },
        {
          video: "about_video_03.mp4",
          text:
            "Le calendrier de l’Après est un projet développé par l’entreprise Cher Ami dans le cadre de ses expérimentations techniques. </br>Plus de projets et de renseignements sur </br><a href='https://www.cher-ami.tv' target='_blank'>www.cher-ami.tv</a>"
        }
      ],
      share: "Partager",
      contact: "Nous contacter"
    },
    calendar: {
      title: "Votre calendrier",
      subtitle: "de l'après",
      text:
        "Scrollez pour redécouvrir votre sélection de résolutions pour 2020."
    }
  };

  static components = {
    footerMenu: {
      camera: "Camera",
      about: "A propos",
      calendar: "Mes resolutions"
    },
    desktopScreen: {
      cta: "Rendez-vous sur mobile",
      subtitle: "pour découvrir l'expérience"
    },
    windowPopin: {
      addToResolutions: "Ajouter à mes résolutions",
      or: "ou",
      close: "Fermer"
    }
  };

  static molecules = {};
}

export default FrFr;
