import "./CameraPage.less";
import React, { RefObject, createRef } from "react";
import { ReactPage } from "../../lib/solidify/react/ReactPage";
import { Helmet } from "react-helmet";
import { prepare } from "../../helpers/prepare";
import CameraTracking from "../../components/cameraTracking";
import Button from "../../components/button";
import FooterMenu from "../../components/footerMenu";
import { gsap, Quad } from "gsap/gsap-core";
import WindowBoxesService from "../../services/WindowBoxesService/WindowBoxesService";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {}
interface IStates {
  isFooterMenuVisible: boolean;
  shouldPredict: boolean;
  mainTitleVisible: boolean;
}
// prepare
const { component, log } = prepare("CameraPage");

// get text data from local
const locale = GlobalConfig.instance.data.pages.camera;

/**
 * @name CameraPage
 */
class CameraPage extends ReactPage<IProps, IStates> {
  // --------------------------------------------------------------------------- INIT

  // get root ref
  protected $root;
  protected $content;
  protected $titleWrapper;

  protected $titleTop;
  protected $titleMiddleLeft;
  protected $titleMiddleRight;
  protected $titleBottom;

  protected displayTitleIntervalID;

  constructor(pProps: IProps, pContext: any) {
    super(pProps, pContext);

    this.$root = createRef();
    this.$content = createRef();
    this.$titleWrapper = createRef();

    this.$titleTop = createRef();
    this.$titleMiddleLeft = createRef();
    this.$titleMiddleRight = createRef();
    this.$titleBottom = createRef();

    this.displayTitleIntervalID = null;

    this.initState({
      isFooterMenuVisible: false,
      shouldPredict: false,
      mainTitleVisible: false
    });
  }

  // --------------------------------------------------------------------------- LIFECYCLE

  componentWillUpdate(
    nextProps: Readonly<IProps>,
    nextState: Readonly<IStates>,
    nextContext: any
  ): void {
    if (this.state.mainTitleVisible !== nextState.mainTitleVisible) {
      this.setMainTitleVisibility(nextState.mainTitleVisible);
    }
  }

  componentWillUnmount(): void {
    // clear display title interval to display it
    clearInterval(this.displayTitleIntervalID);
  }

  // --------------------------------------------------------------------------- TRANSITION

  /**
   * Play in animation.
   * Call complete handler when animation is done.
   */
  protected playInPromiseHandler(pCompleteHandler: () => void) {
    return new Promise(resolve => {
      // show footer, start predictions
      this.setState({
        isFooterMenuVisible: true,
        mainTitleVisible: true
      });

      gsap.delayedCall(3, () => {
        this.setState({
          shouldPredict: true
        });
      });

      // hide title after delay
      gsap.delayedCall(5, () => {
        this.setState({
          mainTitleVisible: false
        });
        // show title back with delay when no windows had been discovered
        this.displayTitleBack();

        pCompleteHandler();
        resolve();
      });
    });
  }

  /**
   * Play out animation.
   * Call complete handler when animation is done.
   */
  protected playOutPromiseHandler(pCompleteHandler: () => void) {
    this.setState({
      shouldPredict: false
    });

    return new Promise(resolve => {
      gsap.set(this.$root.current, { zIndex: 34 });

      gsap.fromTo(
        this.$content.current,
        {
          x: 0,
          y: 0,
          rotation: 0
        },
        {
          duration: 1,
          x: this.$content.current.clientWidth,
          y: -this.$content.current.clientHeight,
          ease: Quad.easeInOut,
          rotation: -40,
          onComplete: () => {
            pCompleteHandler?.();
            resolve();
          }
        }
      );
    });

    //pCompleteHandler();
  }

  // --------------------------------------------------------------------------- ANIMATIONS

  /**
   * Display title message within interval when no windows has been detected
   */
  protected displayTitleBack() {
    const maxCount = 2;
    let count = 0;
    this.displayTitleIntervalID = setInterval(() => {
      const hasMatchedWindows = WindowBoxesService.matchedWindows.length > 0;
      if (!hasMatchedWindows !== this.state.mainTitleVisible) {
        // set title visibility
        this.setState({
          mainTitleVisible: !hasMatchedWindows
        });

        // auto hide after delay
        if (this.state.mainTitleVisible) {
          gsap.delayedCall(4, () => {
            this.setState({
              mainTitleVisible: false
            });
          });

          if (count >= maxCount) {
            clearInterval(this.displayTitleIntervalID);
          }
          count++;
        }
      }
    }, 11000);
  }

  /**
   * Animate title visibility from component state
   * @param isVisible
   */
  protected setMainTitleVisibility(isVisible: boolean) {
    isVisible ? this.showMainTitle() : this.hideMainTitle();
  }

  /**
   * Show and animate main title message for window scan
   */
  protected showMainTitle() {
    const defaultDuration = 0.4;
    gsap.to(this.$titleTop.current, {
      opacity: 1,
      duration: defaultDuration,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleMiddleLeft.current, {
      opacity: 1,
      duration: defaultDuration,
      delay: 0.3,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleMiddleRight.current, {
      opacity: 1,
      duration: defaultDuration,
      delay: 0.6,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleBottom.current, {
      opacity: 1,
      duration: defaultDuration,
      delay: 0.9,
      ease: Quad.easeInOut
    });
  }

  /**
   * Hide and animate main title message for window scan
   */
  protected hideMainTitle() {
    const defaultDuration = 0.3;
    gsap.to(this.$titleTop.current, {
      opacity: 0,
      duration: defaultDuration,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleMiddleLeft.current, {
      opacity: 0,
      duration: defaultDuration,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleMiddleRight.current, {
      opacity: 0,
      duration: defaultDuration,
      ease: Quad.easeInOut
    });
    gsap.to(this.$titleBottom.current, {
      opacity: 0,
      duration: defaultDuration,
      ease: Quad.easeInOut
    });
  }

  // --------------------------------------------------------------------------- RENDER

  render() {
    return (
      <div className={component} ref={this.$root}>
        <Helmet>
          <title>{component}</title>
        </Helmet>

        <div className={`${component}_content`} ref={this.$content}>
          <CameraTracking shouldPredict={this.state.shouldPredict} />
          <div className={`${component}_overlay`} />
          <div className={`${component}_titleWrapper`} ref={this.$titleWrapper}>
            <h1 className={`${component}_title`}>
              <span ref={this.$titleTop} className={`${component}_titleTop`}>
                {locale.scanMessage.scan}
              </span>
              <span
                ref={this.$titleMiddleLeft}
                className={`${component}_titleMiddleLeft`}
              >
                {locale.scanMessage.the}
              </span>
              <span
                ref={this.$titleMiddleRight}
                className={`${component}_titleMiddleRight`}
              >
                {locale.scanMessage.windows}
              </span>
              <span
                ref={this.$titleBottom}
                className={`${component}_titleBottom`}
              >
                {locale.scanMessage.nearby}
              </span>
            </h1>
          </div>

          <FooterMenu
            linkToCamera={false}
            isVisible={this.state.isFooterMenuVisible}
          />
        </div>
      </div>
    );
  }
}

export default CameraPage;
