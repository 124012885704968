import "./Counter.less";
import React, { createRef, useEffect, useRef } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";

interface IProps {
  classNames?: string[];
  count: number;
}

// prepare
const { component, log } = prepare("Counter");

/**
 * @name Counter
 */
function Counter(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const $onesPlaceSequence = useRef(null);
  const $tensPlaceSequence = useRef(null);

  // --------------------------------------------------------------------------- RENDER

  // Set number sequence
  const sequence = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].join("\n");

  /**
   * Update counter and animate number sequence to input number
   * @param number
   */
  const countUpTo = number => {
    const tensPlace = Math.floor(number / 10);
    const onesPlace = number % 10;

    if (tensPlace !== 0) {
      $tensPlaceSequence.current.classList.remove("is-hidden");
      setTimeout(
        () =>
          ($tensPlaceSequence.current.style.transform = `translate3d(0, ${-(
            9 - tensPlace
          ) * 10}%, 0)`),
        0
      );
    } else {
      $tensPlaceSequence.current.classList.add("is-hidden");
    }
    $onesPlaceSequence.current.style.transform = `translate3d(0, ${-(
      9 - onesPlace
    ) * 10}%, 0)`;
  };

  // Update counter when count change
  useEffect(() => {
    countUpTo(props.count);
  }, [props.count]);

  return (
    <div className={classBlock([component, props.classNames])}>
      <div className={`${component}_inner`}>
        <div className={`${component}_digit`}>
          <div className={`${component}_sequence`} ref={$tensPlaceSequence}>
            {sequence}
          </div>
        </div>
        <div className={`${component}_digit`}>
          <div className={`${component}_sequence`} ref={$onesPlaceSequence}>
            {sequence}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
