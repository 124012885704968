import WindowBox from "../services/WindowBoxesService/WindowBox";
import { EventEmitter } from "events";

export const WINDOWS_KEY = "windows_list";

export class WindowData {
  window: WindowBox;
  image: string;
}

export const LocalStorageEventEmitter = new EventEmitter();

class LocalStorageHelper {
  static windows = [];

  static addWindow(pWindow: WindowData) {
    //let local = LocalStorageHelper.getWindows();

    let windowWithSameId = null;

    // Est ce qu'on a déjà cette fenêtre ?
    this.windows.forEach(window => {
      if (window.window.uid == pWindow.window.uid) windowWithSameId = window;
    });

    // Si oui on se contente de la remplacer
    if (windowWithSameId) {
      this.windows[this.windows.indexOf(windowWithSameId)] = pWindow;
    } else {
      this.windows.push(pWindow);
      LocalStorageEventEmitter.emit("add-window", this.windows);
    }
  }

  static getWindows() {
    return this.windows;
  }
}

export default LocalStorageHelper;
