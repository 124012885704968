import "./GridBackground.less";
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import { StringUtils } from "../../lib/solidify/utils/StringUtils";
import Corner from "../corner";

interface IProps {
  classNames?: string[];
  isVisible: boolean;
  hasCorner: boolean;
  text?: string;
  color: EBackgroundColor;
}

export enum EBackgroundColor {
  BLUE,
  GOLD,
  GOLD_LIGHT,
  CAMERA
}

// prepare
const { component, log } = prepare("GridBackground");

/**
 * @name GridBackground
 */
function GridBackground(props: IProps) {
  // --------------------------------------------------------------------------- DOM

  const $root = useRef(null);
  const $datesContainer = useRef(null);

  // --------------------------------------------------------------------------- PREPARE

  // --------------------------------------------------------------------------- USE EFFECTS

  useEffect(() => {
    props.isVisible ? animIn() : animOut();
  }, [props.isVisible]);

  // --------------------------------------------------------------------------- ANIMS

  const animIn = () => {
    gsap.to($datesContainer.current, {
      duration: 0,
      opacity: 0.4
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      duration: 0,
      opacity: 0
    });
  };

  // --------------------------------------------------------------------------- MISC

  const initCalendarTab = () => {
    let calendar = [];

    for (let i = 0; i < 28; i++) {
      let date = (i + 1).toString();
      if (date.length == 1) date = `0${date}`;

      calendar.push(date);
    }

    return calendar;
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <div ref={$root} className={classBlock([component, props.classNames])}>
      <span
        className={`${component}_background ${component}_background-${StringUtils.enumToString(
          props.color,
          EBackgroundColor
        ).toLowerCase()}`}
      />
      <div className={`${component}_datesContainer`}>
        {initCalendarTab().map((value, index) => {
          return (
            <div className={`${component}_date`} key={index}>
              {value}
            </div>
          );
        })}
      </div>
      {props.hasCorner && <Corner withArrow={true} text={props.text} />}
    </div>
  );
}

export default GridBackground;
