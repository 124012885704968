import "./AppView.less";
import { hot } from "react-hot-loader/root";
import React, { Component } from "react";
import {
  ETransitionType,
  ReactViewStack
} from "../../lib/solidify/react/ReactViewStack";
import { Helmet } from "react-helmet";
import { IRouteMatch, Router } from "../../lib/solidify/navigation/Router";
import { IPage } from "../../lib/solidify/navigation/IPage";
import PopinContainer from "../popinContainer/PopinContainer";
import { prepare } from "../../helpers/prepare";
import { gsap } from "gsap";
import DesktopScreen from "../desktopScreen";
import { EDeviceType, EnvUtils } from "../../lib/solidify/utils/EnvUtils";
import { GlobalConfig } from "../../data/GlobalConfig";
import { LocalLanguage } from "../../types";
import { LocaleStruct } from "../../data/LocaleStruct";

// ------------------------------------------------------------------------------- STRUCT

export interface Props {}

export interface States {}

// prepare
const { component, log } = prepare("AppView");

// get data

class AppView extends Component<Props, States> {
  // React view stack, showing pages when route changes
  protected _viewStack: ReactViewStack;
  protected _locale: LocaleStruct;

  // --------------------------------------------------------------------------- INIT

  constructor(props: Props, context: any) {
    super(props, context);

    this._locale = GlobalConfig.instance.data;
  }

  // --------------------------------------------------------------------------- LIFECYCLE

  componentDidMount() {
    // initialize router
    this.initRouter();

    // Hack pourri pour éviter que la bottom-bar laisse un trou au changement d'orientation
    window.addEventListener(
      "orientationchange",
      () => {
        // Announce the new orientation number
        document.documentElement.style.height = "100vh";
        gsap.delayedCall(0.5, () => {
          document.documentElement.style.height = "100%";
        });
      },
      false
    );
  }

  componentWillUnmount() {
    Router.onNotFound.remove(this.routeNotFoundHandler);
    Router.onNotFound.remove(this.routeChangedHandler);
  }

  componentDidUpdate(pPrevProps: Props, pPrevState: States) {}

  // --------------------------------------------------------------------------- ROUTER

  protected initRouter() {
    // Setup viewStack to show pages from Router automatically
    Router.registerStack("main", this._viewStack);

    // Listen to routes not found
    Router.onNotFound.add(this.routeNotFoundHandler, this);
    Router.onRouteChanged.add(this.routeChangedHandler, this);

    // Start router
    Router.start();
  }

  // --------------------------------------------------------------------------- HANDLERS

  /**
   * Transition manager between all React pages.
   * Useful if you want a custom transition behavior other than PAGE_SEQUENTIAL or PAGE_CROSSED.
   * You can setup a generic transition between all pages and do special cases here.
   * If you want to act on pages beyond just playIn and playOut methods, it's recommended to create an interface or an abstract.
   * To enable this feature, set prop transitionType to ETransitionType.CONTROLLED onto ReactViewStack component.
   * @param {HTMLElement} $oldPage Old page HTMLElement. Can be null.
   * @param {HTMLElement} $newPage New page HTMLElement.
   * @param {IPage} pOldPage Old page component instance. Can be null.
   * @param {IPage} pNewPage New page component instance.
   * @return {Promise<any>}
   */
  protected transitionControl(
    $oldPage: HTMLElement,
    $newPage: HTMLElement,
    pOldPage: IPage,
    pNewPage: IPage
  ): Promise<any> {
    return new Promise(async resolve => {
      gsap.set($("body"), { overflow: "hidden" });

      await Promise.all([
        pNewPage.playIn(),
        pOldPage != null
          ? pOldPage.playOut().then(() => {
              gsap.set($("body"), { overflow: "initial" });
              resolve();
            })
          : new Promise(() => {
              gsap.set($("body"), { overflow: "initial" });
              resolve();
            })
      ]);
    });
  }

  /**
   * When route has changed
   */
  protected routeChangedHandler(pRouteMatch: IRouteMatch) {
    log("Route changed", pRouteMatch);
  }

  /**
   * When a route is not found
   */
  protected routeNotFoundHandler(...rest) {
    console.error("ROUTE NOT FOUND", rest);
    // get not found page name
    const pageName = "NotFoundPage";
    // get not found page
    const notFoundPage = () => require("../../pages/notFoundPage/NotFoundPage");
    // show not found page
    this._viewStack.showPage(pageName, notFoundPage, "index", {});
  }

  /**
   * When a page is not found
   * @param {string} pPageName
   */
  protected pageNotFoundHandler(pPageName: string) {
    console.error("PAGE NOT FOUND", pPageName);
  }

  // --------------------------------------------------------------------------- PREPARE

  // --------------------------------------------------------------------------- RENDER

  render() {
    // get landscape screen image url
    const landscapeScreenImageLink = `public/img/landscape_${
      GlobalConfig.instance.locale === LocalLanguage.FR ? "fr" : "en"
    }.jpg`;

    return (
      <div className={component}>
        <Helmet>
          <title>{this._locale.layout.title}</title>
          <meta name="description" content={this._locale.layout.description} />
          <meta
            property="og:description"
            content={this._locale.layout.description}
          />
          <meta
            name="twitter:description"
            content={this._locale.layout.description}
          />
        </Helmet>
        <ReactViewStack
          ref={r => (this._viewStack = r)}
          transitionType={ETransitionType.CONTROLLED}
          transitionControl={this.transitionControl.bind(this)}
          onNotFound={this.pageNotFoundHandler.bind(this)}
        />
        <PopinContainer />
        {EnvUtils.isDeviceType(EDeviceType.DESKTOP) && <DesktopScreen />}
        <span
          className={`${component}_landscapeScreen`}
          style={{ backgroundImage: `url("${landscapeScreenImageLink}")` }}
        />
      </div>
    );
  }
}

export default hot(AppView);
