import "./Corner.less";
import React, { MutableRefObject, RefObject, useEffect, useRef } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import ArrowButton from "components/arrowButton/ArrowButton";
import Lottie from "lottie-web";
import Anims from "../../data/Anims";
import { gsap, Quad } from "gsap";
//import { ReactComponent as Logo } from './fleche.svg';

interface IProps {
  classNames?: string[];
  withArrow?: boolean;
  withAnimatedArrow?: boolean;
  arrowVisible?: boolean;
  animateArrowDelay?: number;
  text?: string;
}

// prepare
const { component, log } = prepare("Corner");

/**
 * @name Corner
 */
function Corner(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const $swipeArrow: MutableRefObject<HTMLElement> = useRef<HTMLElement>(null);
  const $swipeText: RefObject<HTMLParagraphElement> = useRef<
    HTMLParagraphElement
  >(null);

  // --------------------------------------------------------------------------- LIFECYCLE

  useEffect(() => {
    props.arrowVisible && displayArrow();
  }, [props.arrowVisible]);

  // --------------------------------------------------------------------------- LIFECYCLE

  // play in swipe arrow
  const displayArrow = () => {
    const animateArrowDelay = props.animateArrowDelay || 0;
    gsap.delayedCall(animateArrowDelay, () => {
      // show arrow
      Lottie.loadAnimation({
        container: $swipeArrow.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: Anims.swipeArrow,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax meet"
        }
      });
    });
    // show text
    gsap.fromTo(
      $swipeText.current,
      0.7,
      { opacity: 0, x: -10 },
      { opacity: 1, x: 0, delay: 0.8 + animateArrowDelay }
    );
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={classBlock([component, props.classNames])}>
      <span className={`${component}_triangle`} />
      {props.withArrow && (
        <ArrowButton withArrow={true} angle={45} classNames={[]} />
      )}
      {props.withAnimatedArrow && (
        <span className={`${component}_animatedArrow`} ref={$swipeArrow} />
      )}
      {props.text && (
        <p className={`${component}_text`} ref={$swipeText}>
          {props.text}
        </p>
      )}
    </div>
  );
}

export default Corner;
