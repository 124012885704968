import "./DesktopScreen.less";
import React from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import GridBackground, {
  EBackgroundColor
} from "../gridBackground/GridBackground";
import { GlobalConfig } from "../../data/GlobalConfig";
import FrFr from "../../data/locales/FrFr";

interface IProps {
  classNames?: string[];
}

// prepare
const { component, log } = prepare("DesktopScreen");

/**
 * @name DesktopScreen
 */
function DesktopScreen(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const locale = GlobalConfig.instance.data.components.desktopScreen;

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={classBlock([component, props.classNames])}>
      <GridBackground
        isVisible={true}
        color={EBackgroundColor.BLUE}
        hasCorner={false}
      />
      <div className={`${component}_content`}>
        <img className={`${component}_title`} src={`public/img/titre.png`} />
        <div className={`${component}_bottom`}>
          <p className={`${component}_cta`}>{locale.cta}</p>
          <p className={`${component}_subtitle`}>{locale.subtitle}</p>
          <div className={`${component}_qrContainer`}>
            <img className={`${component}_qr`} src={`public/img/qr.png`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopScreen;
