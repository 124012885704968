import { useEffect, useState } from "react";
import AppConfig from "../data/AppConfig";
import { GlobalConfig } from "data/GlobalConfig";

const useWebcam = videoRef => {
  if (!videoRef) {
    console.log("no video ref");
    return {
      webcamLoaded: false
    };
  }

  const [webcamLoaded, setWebcamLoaded] = useState(false);

  useEffect(() => {
    if (AppConfig.webcam) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              facingMode: "environment"
            }
          })
          .then(stream => {
            // window.stream = stream
            videoRef.current.srcObject = stream;
            videoRef.current.onloadedmetadata = () => {
              setWebcamLoaded(true);

              videoRef.current.width = videoRef.current.videoWidth;
              videoRef.current.height = videoRef.current.videoHeight;
            };
          });
      }
    } else {
      videoRef.current.src = AppConfig.debugVideoPath;
      videoRef.current.onloadedmetadata = () => {
        setWebcamLoaded(true);

        videoRef.current.width = videoRef.current.videoWidth;
        videoRef.current.height = videoRef.current.videoHeight;
      };
    }
  }, [videoRef]);
  return {
    webcamLoaded
  };
};

export default useWebcam;
