import { EventEmitter } from "events";
import WindowBox from "./WindowBox";
import videoFrameService from "../videoFrameService";
import RenderConstants from "../../data/RenderConstants";

/**
 * Manager for window boxes on screen
 */
class WindowBoxesService {
  private eventEmitter: EventEmitter;
  private windows: WindowBox[];
  private windowIndex: number;
  private currentPredictionMatched: boolean;
  private oldTime: number;

  public matchedWindows: WindowBox[];
  public rootElement: HTMLElement;

  constructor() {
    this.windows = [];
    this.matchedWindows = [];
    this.windowIndex = 0;
    this.currentPredictionMatched = false;
    this.oldTime = Date.now();

    this.eventEmitter = new EventEmitter();
  }

  on(event: string, callback: (...args: any[]) => void) {
    return this.eventEmitter.on(event, callback);
  }

  off(event: string, callback: (...args: any[]) => void) {
    return this.eventEmitter.off(event, callback);
  }

  onWindowClick(windowBox: WindowBox) {
    this.eventEmitter.emit("window-box-click", windowBox);
  }

  /**
   * Check window boxes predictions for similar ones for continuity
   * @param prediction - The current prediction data
   * @private
   */
  checkSimilarity(prediction) {
    for (
      let windowIndex = 0;
      windowIndex < this.windows.length;
      windowIndex++
    ) {
      const currentWindow = this.windows[windowIndex];

      if (this.matchedWindows.includes(currentWindow)) continue;

      const similarity = currentWindow.getSimilarity(prediction);

      const scaleFactor =
        Math.sqrt(
          currentWindow.boundingBox[2] * currentWindow.boundingBox[2] +
            currentWindow.boundingBox[3] * currentWindow.boundingBox[3]
        ) / videoFrameService.hypoth;

      const scaleSimilarityTreshold =
        RenderConstants.BASE_SIMILARITY_THRESHOLD -
        (scaleFactor - 0.5) * RenderConstants.SCALE_FACTOR_EASE;

      // Add current window to matched windows if similarity score good enough
      if (similarity > scaleSimilarityTreshold) {
        this._addToMatched(currentWindow, prediction);
        break;
      }
    }
  }

  /**
   * Add selected windows object from model prediction on current frame
   * @param predictions model predictions
   */
  addWindows(predictions) {
    this.matchedWindows = [];

    for (
      let predictionIndex = 0;
      predictionIndex < predictions.length;
      predictionIndex++
    ) {
      const prediction = predictions[predictionIndex];
      if (prediction.score < RenderConstants.SCORE_TRESHOLD) continue;

      this.currentPredictionMatched = false;

      // Test similarity
      this.checkSimilarity(prediction);

      // Add new window to matched windows
      if (!this.currentPredictionMatched) {
        this.createWindow(prediction.bbox);
      }
    }
  }

  /**
   * Update current window boxes stability score to manage visibility on current frame
   */
  updateStabilities() {
    const time = Date.now();
    const deltaTime = time - this.oldTime;
    this.oldTime = time;

    const deltaCapped = Math.min(deltaTime, 200);

    for (
      let windowIndex = this.windows.length - 1;
      windowIndex >= 0;
      --windowIndex
    ) {
      const currentWindow = this.windows[windowIndex];

      // matched
      if (this.matchedWindows.includes(currentWindow)) {
        currentWindow.stabilityScore += 0.015 * deltaCapped;
        currentWindow.stabilityScore = Math.min(
          currentWindow.stabilityScore,
          1
        );
      }
      // not matched
      else {
        currentWindow.stabilityScore -= 0.001 * deltaCapped;

        // If has just been re-matched in current frame
        if (currentWindow.isMatched) {
          currentWindow.isMatched = false;
          currentWindow.unmatchTime = time;
        }
      }

      if (currentWindow.isOverflowing()) {
        currentWindow.stabilityScore /= 2;
      }

      // Update final visibility

      // Show if not already stable and stability score is over 1
      if (!currentWindow.stable && currentWindow.stabilityScore >= 1) {
        currentWindow.stable = true;
        currentWindow.show();
      } else if (currentWindow.stable && currentWindow.stabilityScore <= 0.7) {
        // Hide if stable but score below treshold
        currentWindow.stable = false;
        currentWindow.hide();
      }

      // Delete if score drop below 0
      if (currentWindow.stabilityScore <= 0) {
        this.windows.splice(windowIndex, 1);
        currentWindow.delete();
      }
    }
  }

  update(predictions) {
    this.addWindows(predictions);
    this.updateStabilities();
  }

  reset() {
    this.windows.forEach((windowBox: WindowBox) => windowBox.delete());
    this.windows = [];
  }

  /**
   * Add found window to matched windows
   * @param {object} currentWindow - Current found window
   * @param {object} prediction - Window prediction data
   * @private
   */
  _addToMatched(currentWindow: WindowBox, prediction) {
    this.matchedWindows.push(currentWindow);
    this.currentPredictionMatched = true;
    currentWindow.setBoundingBox(prediction.bbox);
    currentWindow.isMatched = true;
  }

  /**
   * Create Window
   * @param {object} boundingBox - Prediction bounding box data
   */
  createWindow(boundingBox) {
    const index = this.windowIndex;
    const newWindow = new WindowBox({
      boundingBox,
      index,
      rootElement: this.rootElement
    });
    this.windowIndex++;
    newWindow.isMatched = true;
    this.windows.push(newWindow);
    this.matchedWindows.push(newWindow);
  }
}

export default new WindowBoxesService();
