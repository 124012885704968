class BoundingBoxHelper {
  static mediumDistanceTo(bboxA, bboxB) {
    // x, y
    const distancePoint0 = this.distanceToPoints(
      bboxA[0],
      bboxA[1],
      bboxB[0],
      bboxB[1]
    );
    // x + w, y
    const distancePoint1 = this.distanceToPoints(
      bboxA[0] + bboxA[2],
      bboxA[1],
      bboxB[0] + bboxB[2],
      bboxB[1]
    );
    // x + w, y + h

    // FIX: confusion bboxB[1] + bboxA[3]
    const distancePoint2 = this.distanceToPoints(
      bboxA[0] + bboxA[2],
      bboxA[1] + bboxA[3],
      bboxB[0] + bboxB[2],
      bboxB[1] + bboxA[3]
    );
    // x, y + h

    // FIX: confusion bboxB[1] + bboxA[3],
    const distancePoint3 = this.distanceToPoints(
      bboxA[0],
      bboxA[1] + bboxA[3],
      bboxB[0],
      bboxB[1] + bboxA[3]
    );

    return (
      (distancePoint0 + distancePoint1 + distancePoint2 + distancePoint3) / 4
    );
  }

  static distanceToPoints(pointAX, pointAY, pointBX, pointBY) {
    const a = pointAX - pointBX;
    const b = pointAY - pointBY;
    return Math.sqrt(a * a + b * b);
  }

  static containsPoint(bbox, x, y) {
    return (
      x >= bbox[0] &&
      x <= bbox[0] + bbox[2] &&
      y >= bbox[1] &&
      y <= bbox[1] + bbox[3]
    );
  }
}

export default BoundingBoxHelper;
