/**
 * Default play state enum
 */
export enum EPlayState {
  HIDDEN,
  PLAY_OUT,
  PLAY_IN,
  VISIBLE
}

/**
 * Default play state enum
 */
export enum LocalLanguage {
  FR = "fr",
  EN = "en"
}
