//@ts-nocheck
import BoundingBoxHelper from "../../helpers/boundingBoxHelper";
import videoFrameService from "../videoFrameService";
import windowBoxesService from "./WindowBoxesService";

import tweetsFr from "../../data/tweets";
import tweetsEn from "../../data/tweets.en";
import { GlobalConfig } from "../../data/GlobalConfig";

const WINDOW_SCALE = 0.9;

const tweets =
  GlobalConfig.instance.locale.indexOf("fr") >= 0 ? tweetsFr : tweetsEn;

class WindowBox {
  private index: number;
  private rootElement: HTMLElement;
  private color: string;
  private htmlElement: HTMLElement;

  public uid: string;
  public boundingBox: number[];
  public stabilityScore: number;
  public stable: boolean;
  public isMatched: boolean;
  public unmatchTime: number;
  public tweet: object;

  constructor({ boundingBox, index, rootElement }) {
    this.index = index;
    this.tweet = tweets[Math.floor(Math.random() * tweets.length)];
    this.uid = Math.random()
      .toString(36)
      .substr(2, 9);

    this.setBoundingBox(boundingBox);
    this.rootElement = rootElement;

    this.color =
      "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
    this.stabilityScore = 0;
    this.stable = false;
    this.htmlElement = null;
  }

  getSimilarity(prediction) {
    const mediumDistance = BoundingBoxHelper.mediumDistanceTo(
      this.boundingBox,
      prediction.bbox
    );
    const displacement = Math.min(
      Math.max(mediumDistance / videoFrameService.hypoth, 0),
      1
    );
    return 1 - displacement;
  }

  getSquareBoundingBox() {
    const maxSize = Math.min(videoFrameService.width, videoFrameService.height);
    const size = Math.min(
      Math.max(this.boundingBox[2], this.boundingBox[3]) * WINDOW_SCALE,
      maxSize
    );
    const offsetLeft = (size - this.boundingBox[2]) / 2;
    const offsetTop = (size - this.boundingBox[3]) / 2;

    let left = this.boundingBox[0] - offsetLeft;
    let top = this.boundingBox[1] - offsetTop;

    // if (left < 0) {
    //   left = 0
    // }
    // if ((left + size) > videoFrameService.width) {
    //   left = left - (left + size - videoFrameService.width);
    // }
    // if (top < 0) {
    //   top = 0
    // }
    // if ((top + size) > videoFrameService.height) {
    //   top = top - (top + size - videoFrameService.height);
    // }

    return [left, top, size, size];
  }

  isOverflowing() {
    const squareBoundingBox = this.getSquareBoundingBox();

    if (
      squareBoundingBox[0] < 0 ||
      squareBoundingBox[0] + squareBoundingBox[2] > videoFrameService.width ||
      squareBoundingBox[1] < 0 ||
      squareBoundingBox[1] + squareBoundingBox[2] > videoFrameService.height
    ) {
      return true;
    }

    return false;
  }

  createHtmlElement() {
    this.htmlElement = document.createElement("div");

    this.htmlElement.innerHTML = `<div class="windowInner">
                                        <div class="windowContent">
                                            <div class="windowContent_sprite windowContent_sprite-sprite${this
                                              .index % 5}"></div>
                                        </div>
                                  </div>`;

    this.htmlElement.className = "windowElement";

    this.htmlElement.onclick = () => windowBoxesService.onWindowClick(this);
    if (this.rootElement) {
      this.rootElement.appendChild(this.htmlElement);
    }
  }

  show() {
    if (!this.htmlElement) {
      this.createHtmlElement();
    }

    this.updateHtmlElement();

    setTimeout(() => {
      this.htmlElement.classList.add("visible");
    }, 100);
  }

  hide() {
    if (!this.htmlElement) {
      return;
    }
    this.htmlElement.classList.remove("visible");
  }

  delete() {
    if (!this.htmlElement) {
      return;
    }
    if (this.rootElement) {
      // Hide with delay for animation
      setTimeout(() => {
        this.rootElement.removeChild(this.htmlElement);
      }, 500);
    }
  }

  setBoundingBox(boundingBox) {
    this.boundingBox = boundingBox;

    const centerX = this.boundingBox[0] + this.boundingBox[2] / 2;
    const centerY = this.boundingBox[1] + this.boundingBox[3] / 2;

    if (!this.center) this.center = [centerX, centerY];

    // Add a small lerp
    this.center[0] = this.center[0] + (centerX - this.center[0]) * 0.3;
    this.center[1] = this.center[1] + (centerY - this.center[1]) * 0.3;

    if (!this.htmlElement) return;

    this.updateHtmlElement();
  }

  updateHtmlElement() {
    if (!this.htmlElement) return;
    if (!this.center) return;

    const squareBoundingBox = this.getSquareBoundingBox();

    this.htmlElement.style.left =
      videoFrameService.offsetX +
      this.center[0] * videoFrameService.scale +
      "px";
    this.htmlElement.style.top =
      videoFrameService.offsetY +
      this.center[1] * videoFrameService.scale +
      "px";

    this.htmlElement.style.transform = `scale(${(squareBoundingBox[2] *
      videoFrameService.scale) /
      256})`;
  }
}
export default WindowBox;
