import "./ArrowButton.less";
import React, { Fragment } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
//import { ReactComponent as Logo } from './fleche.svg';

interface IProps {
  classNames?: string[];
  angle?: number;
  isLarge?: boolean;
  children?: any;
  onClick?: () => void;
  withArrow?: boolean;
}

// prepare
const { component, log } = prepare("ArrowButton");

/**
 * @name ArrowButton
 */
function ArrowButton(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // --------------------------------------------------------------------------- RENDER

  return (
    <div
      className={classBlock([
        component,
        ...props.classNames,
        props.isLarge ? `${component}--large` : ""
      ])}
      onClick={props.onClick}
    >
      <div className={`${component}_container`}>
        <div
          className={`${component}_arrow`}
          style={{
            transform: props.angle ? `rotate(${-props.angle}deg)` : ""
          }}
        >
          <span className={`${component}_circle`} />
          {props.withArrow && (
            <Fragment>
              <span className={`${component}_line`} />
              <span className={`${component}_triangle`} />
            </Fragment>
          )}
        </div>

        {props.children && (
          <span className={`${component}_icon`}>{props.children}</span>
        )}
      </div>
    </div>
  );
}

export default ArrowButton;
