import "./AboutPopin.less";
import React, { useEffect, useRef, useState } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import ArrowButton from "../arrowButton/ArrowButton";
import { gsap } from "gsap/gsap-core";
import popinService from "../../services/popinService";
import Lottie from "lottie-web";
import Anims from "../../data/Anims";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {
  classNames?: string[];
}

// prepare
const { component, log } = prepare("AboutPopin");
const locale = GlobalConfig.instance.data.pages.about;
/**
 * @name AboutPopin
 */
function AboutPopin(props: IProps) {
  // --------------------------------------------------------------------------- DOM

  const $root = useRef(null);
  const $anim = useRef(null);

  // --------------------------------------------------------------------------- PREPARE

  const [isVisible, setIsVisible] = useState(true);

  // --------------------------------------------------------------------------- USE EFFECTS

  useEffect(() => {
    isVisible ? animIn() : animOut();
  }, [isVisible]);

  // --------------------------------------------------------------------------- ANIM

  const animIn = () => {
    gsap.to($root.current, {
      opacity: 1
    });

    Lottie.loadAnimation({
      container: $anim.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: Anims.homePagePetals,
      rendererSettings: {
        preserveAspectRatio: "xMaxYMax meet"
      }
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      opacity: 0,
      onComplete: () => {
        popinService.hide();
      }
    });
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <div ref={$root} className={classBlock([component, props.classNames])}>
      <span className={`${component}_anim`} ref={$anim} />
      <h1 className={`${component}_title`}>{locale.title}</h1>
      {locale.paragraphs.map((value, index) => {
        return (
          <div
            key={index}
            className={`${component}_paragraph ${component}_paragraph-${index}`}
          >
            {value.image && (
              <img
                className={`${component}_paragraphImg ${component}_paragraphImg-${index}`}
                src={`public/img/${value.image}`}
              />
            )}
            {value.video && (
              <video
                className={`${component}_paragraphVideo ${component}_paragraphVideo-${index}`}
                autoPlay={true}
                playsInline={true}
                loop={true}
                muted={true}
              >
                <source src={`public/video/${value.video}`} />
              </video>
            )}
            {value.text && (
              <p
                className={`${component}_paragraphText ${component}_paragraphText-${index}`}
                dangerouslySetInnerHTML={{ __html: value.text }}
              />
            )}
          </div>
        );
      })}
      <p className={`${component}_sectionTitle`}>{locale.share}</p>
      <div className={`${component}_section ${component}_section-share`}>
        <a
          href={
            "https://www.facebook.com/sharer/sharer.php?u=https://lecalendrierdelapres.cher-ami.tv/"
          }
          target={"_blank"}
        >
          <img className={`${component}_shareIcon`} src={"public/img/fb.png"} />
        </a>
        <a
          href={
            "https://twitter.com/intent/tweet?&text=https%3A%2F%2Flecalendrierdelapres.cher-ami.tv%2F"
          }
          target={"_blank"}
        >
          <img
            className={`${component}_shareIcon ${component}_shareIcon-tw`}
            src={"public/img/tw.png"}
          />
        </a>
      </div>
      <p className={`${component}_sectionTitle`}>{locale.contact}</p>
      <div className={`${component}_section ${component}_section-contact `}>
        <a href={"mailto:contact@cher-ami.tv"}>
          <img
            className={`${component}_contactIcon`}
            src={"public/img/contact.png"}
          />
        </a>
      </div>
      <ArrowButton
        classNames={[
          `${component}_arrowButton`,
          `${component}_arrowButton--left`
        ]}
        isLarge={true}
        angle={180}
        onClick={() => {
          setIsVisible(false);
        }}
      >
        <span className={"ArrowButton_svgIcon ArrowButton_svgIcon-close"} />
      </ArrowButton>
    </div>
  );
}

export default AboutPopin;
