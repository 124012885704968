/**
 * Current frame sizes infos
 */

enum EDisplayMode {
  COVER,
  CONTAIN
}

class VideoFrameService {
  private _videoRef;
  private displayMode: EDisplayMode;

  public set videoRef(videoRef) {
    if (!videoRef || !videoRef.current) return;

    this._videoRef = videoRef;

    this.update();
  }

  public width;
  public height;
  public scale;
  public offsetX;
  public offsetY;
  public hypoth;

  constructor() {
    this.scale = 1;
    this.offsetX = 0;
    this.offsetY = 0;
    this.hypoth = 0;

    this.displayMode = EDisplayMode.COVER;

    window.addEventListener("resize", this.update.bind(this));
  }

  update() {
    if (!this._videoRef || !this._videoRef.current) {
      // Fallback
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    } else {
      this.width = this._videoRef.current.videoWidth;
      this.height = this._videoRef.current.videoHeight;
    }

    this.setScale();
    this.setOffset();
    this.setHypoth();
  }

  protected setScale() {
    const scaleX = window.innerWidth / this.width;
    const scaleY = window.innerHeight / this.height;

    if (this.displayMode === EDisplayMode.CONTAIN) {
      this.scale = Math.min(scaleX, scaleY);
    } else if (this.displayMode === EDisplayMode.COVER) {
      this.scale = Math.max(scaleX, scaleY);
    }
  }

  protected setOffset() {
    this.offsetX = (window.innerWidth - this.width * this.scale) / 2;
    this.offsetY = (window.innerHeight - this.height * this.scale) / 2;
  }

  protected setHypoth() {
    this.hypoth = Math.sqrt(
      this.width * this.width + this.height * this.height
    );
  }
}

export default new VideoFrameService();
