import { EventEmitter } from "events";
import { ReactNode } from "react";

class PopinService {
  private element: ReactNode;
  private eventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  on(event: string, callback: (...args: any[]) => void) {
    return this.eventEmitter.on(event, callback);
  }

  off(event: string, callback: (...args: any[]) => void) {
    return this.eventEmitter.off(event, callback);
  }

  public show(element: ReactNode) {
    this.element = element;
    this.eventEmitter.emit("show", element);
  }

  public hide() {
    this.eventEmitter.emit("hide");
  }
}

export default new PopinService();
