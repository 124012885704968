import "./CameraBackground.less";
import React, { useEffect, useRef, useState } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import { gsap, Quad } from "gsap/gsap-core";

interface IProps {
  classNames?: string[];
  isVisible: boolean;
}

// prepare
const { component, log } = prepare("CameraBackground");

/**
 * @name CameraBackground
 */
function CameraBackground(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const $root = useRef();

  const classBlockName = classBlock([component, props.classNames]);

  // --------------------------------------------------------------------------- USE EFFECT

  useEffect(() => {
    props.isVisible ? animIn() : animOut();
  }, [props.isVisible]);

  const animIn = () => {
    gsap.to($root.current, {
      opacity: 1,
      duration: 0,
      ease: Quad.easeInOut
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      opacity: 0,
      duration: 0,
      ease: Quad.easeInOut
    });
  };

  // --------------------------------------------------------------------------- RENDER

  return <div ref={$root} className={classBlockName}></div>;
}

export default CameraBackground;
