import { LocaleStruct } from "../LocaleStruct";

export class EnEn extends LocaleStruct {
  /**
   * Traductions sur le layout
   */
  static layout = {
    title: "Le Calendrier de l'Après",
    description:
      "An original and innovative online experience combining augmented reality, social content & artificial intelligence to wish you an inspired New Year."
  };

  /**
   * Traductions dans les sections
   */
  static pages = {
    home: {
      // TODO: add hidden in homepage for SEO
      title: "Le calendrier de l'après"
    },
    notFound: {
      title: "Oups...",
      text: "The page you looking for doesn't exist !"
    },
    tutorial: {
      slides: [
        {
          title: "Use your mobile phone",
          subtitle: "to scan your surroundings"
        },
        {
          title: "Discover different resolutions",
          subtitle: "by clicking on the windows of the buildings"
        },
        {
          title: "Select the best ones",
          subtitle: "to create your Calendrier de l’Après"
        }
      ]
    },
    camera: {
      scanMessage: {
        scan: "Scan",
        the: "the",
        windows: "windows",
        nearby: "nearby"
      },
      stabilityMessage: "Stabilize the device"
    },
    about: {
      title: "about",
      paragraphs: [
        {
          text:
            "To wish you an inspired New Year, Cher Ami has designed the “<b>Calendrier de l’Après</b>”, an original and innovative online experience combining <b>augmented reality, social content & artificial intelligence</b>"
        },
        {
          image: "fenetres_1.png",
          text:
            "Using your smartphone, open the experience URL and point the camera at any window of a nearby building. You can then click on certain windows to discover the New Year’s resolutions posted on Twitter near you. If you like a resolution, it will be saved in your “<b>Calendrier de l’Après</b>” for 2020. <br><br> In technical terms, this experience is based on a combination of original and complex technologies. First, we have created and trained an artificial intelligence program to detect and reliably identify windows."
        },
        {
          video: "calendrier_fenetres.mp4",
          text:
            "We then optimised this program to operate in real time on a web page and on numerous objects. This enabled us to associate small, augmented-reality animations presenting New Year’s resolutions for 2020 posted on Twitter with each window detected by the artificial intelligence program."
        },
        {
          video: "about_video_03.mp4",
          text:
            "Le Calendrier de l’Après is a project developed by the company Cher Ami within the framework of a series of technical experimentations. </br>More projects and information sur </br><a href='https://www.cher-ami.tv' target='_blank'>www.cher-ami.tv</a>"
        }
      ],
      share: "Share",
      contact: "Contact us"
    },
    calendar: {
      title: "Your Calendrier",
      subtitle: "de l'Après",
      text: "Scroll up to see your selection of resolutions for 2020."
    }
  };

  static components = {
    footerMenu: {
      camera: "Camera",
      about: "About",
      calendar: "My resolutions"
    },
    desktopScreen: {
      cta: "Grab your mobile phone",
      subtitle: "and discover the experience"
    },
    windowPopin: {
      addToResolutions: "Add to my resolutions",
      or: "or",
      close: "Close"
    }
  };

  static molecules = {};
}

export default EnEn;
