import "./WindowPopin.less";
import React, { useEffect, useRef, useState } from "react";
import { classBlock, className } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import popinService from "services/popinService";
import WindowBox from "services/WindowBoxesService/WindowBox";
import ArrowButton from "components/arrowButton/ArrowButton";
import { gsap } from "gsap";
import LocalStorageHelper from "../../helpers/localStorageHelper";
import Button from "../button";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {
  classNames?: string[];
  canSave?: boolean;
  windowBox: WindowBox;
  image: string;
}

// Prepare
const { component, log } = prepare("WindowPopin");

/**
 * @name WindowPopin
 */
function WindowPopin(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE
  const $root = useRef(null);

  // get locale data from global data
  const locale = GlobalConfig.instance.data.components.windowPopin;

  const [isVisible, setIsVisible] = useState(true);

  // --------------------------------------------------------------------------- USE EFFECTS

  useEffect(() => {
    isVisible ? animIn() : animOut();
  }, [isVisible]);

  // --------------------------------------------------------------------------- ANIM

  const animIn = () => {
    gsap.to($root.current, {
      opacity: 1
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      opacity: 0,
      onComplete: () => {
        popinService.hide();
      }
    });
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <div ref={$root} className={classBlock([component, props.classNames])}>
      <div className={`${component}_content`}>
        <h2 className={`${component}_userName`}>
          {props.windowBox.tweet["userName"]}
        </h2>
        <h3 className={`${component}_userScreenName`}>
          <img
            className={`${component}_twitter-icon`}
            src={"public/img/twitter.svg"}
          />
          @{props.windowBox.tweet["userScreenName"]}
        </h3>
        <p
          className={`${component}_text`}
          dangerouslySetInnerHTML={{ __html: props.windowBox.tweet["text"] }}
        />
        <div className={`${component}_footer`}>
          {props.canSave && (
            <Button
              className={`${component}_addButton`}
              onClick={() => {
                LocalStorageHelper.addWindow({
                  window: props.windowBox,
                  image: props.image
                });

                setIsVisible(false);
              }}
            >
              {locale.addToResolutions}
            </Button>
          )}
          <div className={`${component}_closeText`}>
            {props.canSave && locale.or}
            <Button
              className={`${component}_closeButton`}
              onClick={() => {
                setIsVisible(false);
              }}
            >
              {locale.close}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowPopin;
