import "./FooterMenu.less";
import React, { useEffect, useRef, useState } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import Corner from "../corner";
import Button from "../button";
import { gsap, Back, Power4 } from "gsap";
import { Router } from "../../lib/solidify/navigation/Router";
import { EPage } from "../../Main";
import popinService from "../../services/popinService";
import AboutPopin from "../aboutPopin";
import Counter from "../counter";
import LocalStorageHelper, {
  LocalStorageEventEmitter
} from "../../helpers/localStorageHelper";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {
  classNames?: string[];
  linkToCamera: boolean;
  isVisible: boolean;
}

interface IStates {
  calendarCount: number;
}

// prepare
const { component, log } = prepare("FooterMenu");

const locale = GlobalConfig.instance.data.components.footerMenu;

/**
 * @name FooterMenu
 */
function FooterMenu(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // Set initial state
  const [state, setState] = useState<IStates>({
    calendarCount: 0
  });

  // refs
  const $root = useRef(null);
  const $calendarCounterCircle1 = useRef(null);
  const $calendarCounterCircle2 = useRef(null);
  const $calendarCounterCircle3 = useRef(null);

  // --------------------------------------------------------------------------- HANDLERS

  const addWindowCalendarHandler = registerWindows => {
    // update calendar windows count and animate circles
    animateCalendarCounterUpdate(registerWindows.length);
  };

  // --------------------------------------------------------------------------- USE EFFECT

  useEffect(() => {
    props.isVisible ? animIn() : animOut();
  }, [props.isVisible]);

  // global effect
  useEffect(() => {
    // set default count from registered windows in localstorate
    setState({
      calendarCount: LocalStorageHelper.getWindows().length
    });

    // update calendar count on menu on new window in calendar
    LocalStorageEventEmitter.on("add-window", addWindowCalendarHandler);

    return () =>
      LocalStorageEventEmitter.off("add-window", addWindowCalendarHandler);
  }, []);

  // --------------------------------------------------------------------------- ANIM

  const animIn = () => {
    gsap.to($root.current, {
      y: "0%",
      opacity: 1,
      duration: 0.5
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      y: "200%",
      opacity: 0,
      duration: 0.5
    });
  };

  const animateCalendarCounterUpdate = (count: number) => {
    const tl = new gsap.core.Timeline();
    // update counter to animate in Count
    tl.call(() => setState({ calendarCount: count }), [], "+=0.7")
      // animate circles
      .fromTo(
        $calendarCounterCircle1.current,
        0.3,
        { scale: 1 },
        { scale: 0.7, ease: Power4.easeOut }
      )
      .to($calendarCounterCircle1.current, 0.5, {
        scale: 1,
        ease: Back.easeOut.config(9)
      })
      .fromTo(
        $calendarCounterCircle2.current,
        0.5,
        { scale: 1, opacity: 1 },
        { scale: 1.6, opacity: 0, ease: Power4.easeOut },
        "-=0.3"
      )
      .fromTo(
        $calendarCounterCircle3.current,
        0.6,
        { scale: 1, opacity: 1 },
        { scale: 1.6, opacity: 0, ease: Power4.easeOut },
        "-=0.3"
      );
  };

  // --------------------------------------------------------------------------- RENDER

  return (
    <div ref={$root} className={classBlock([component, props.classNames])}>
      <Corner />
      <div className={`${component}_container`}>
        {props.linkToCamera ? (
          <Button
            className={`${component}_camera`}
            onClick={() => {
              Router.openURL(Router.generateURL({ page: EPage.CameraPage }));
            }}
          >
            <img
              className={`${component}_icon`}
              src={"public/img/camera.svg"}
            />
            <p className={`${component}_iconText`}>{locale.camera}</p>
          </Button>
        ) : (
          <Button
            className={`${component}_calendar`}
            onClick={() => {
              Router.openURL(
                Router.generateURL({ page: EPage.MyCalendarPage })
              );
            }}
          >
            <div className={`${component}_calendar_counter`}>
              <Counter count={state.calendarCount} />
              <div
                className={`${component}_calendar_counter-circle `}
                ref={$calendarCounterCircle1}
              />
              <div
                className={`${component}_calendar_counter-circle`}
                ref={$calendarCounterCircle2}
              />
              <div
                className={`${component}_calendar_counter-circle`}
                ref={$calendarCounterCircle3}
              />
            </div>
            <p className={`${component}_iconText`}>{locale.calendar}</p>
          </Button>
        )}
        <Button
          className={`${component}_about`}
          onClick={() => {
            popinService.show(<AboutPopin />);
          }}
        >
          <p className={`${component}_iconText`}>{locale.about}</p>
          <img className={`${component}_icon`} src={"public/img/info.png"} />
        </Button>
      </div>
    </div>
  );
}

export default FooterMenu;
