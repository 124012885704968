import "./CameraTracking.less";
import React, { useEffect, useRef, useState } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import useWebcam from "../../hooks/useWebcam";
import useModel from "../../hooks/useModel";
import useDeviceMotion from "../../hooks/useDeviceMotion";
import useBoxRenderer from "../../hooks/useBoxRenderer";
import AlertDialog from "../alertDialog";
import BoxRenderer from "../boxRenderer/BoxRenderer";
import AppConfig from "../../data/AppConfig";
import popinService from "../../services/popinService";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {
  classNames?: string[];
  shouldPredict: boolean;
}

interface IStates {
  showStabiliseMessageInterval: boolean;
}

// prepare
const { component, log } = prepare("CameraTracking");

/**
 * @name CameraTracking
 */
function CameraTracking(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // get locale data from global data
  const locale = GlobalConfig.instance.data.pages.camera;

  // Set initial state
  const [state, setState] = useState<IStates>({
    showStabiliseMessageInterval: false
  });

  const videoRef = useRef();

  // Set webcam stream to video ref
  const { webcamLoaded } = useWebcam(videoRef);

  // Get window detection model
  const model = useModel(AppConfig.modelPath);

  // Get device motion
  const { hasDeviceMotion, isDeviceMovingFast } = useDeviceMotion();

  // get & start box renderer
  const { startPrediction, stopPrediction } = useBoxRenderer(
    model,
    videoRef,
    webcamLoaded
  );

  // update prediction tracking state
  useEffect(() => {
    setPredictionRunning(props.shouldPredict);
  }, [props.shouldPredict]);

  // events on model and webcam loaded
  useEffect(() => {
    if (webcamLoaded && model) {
      // On popin show stop prediction run on camera frames
      popinService.on("show", () => {
        setPredictionRunning(false);
      });
      // On popin hide resume prediction run on camera frames
      popinService.on("hide", () => {
        setPredictionRunning(true);
      });
    }
  }, [webcamLoaded, model]);

  // handle moving too fast message visibility
  useEffect(() => {
    // update stabilise message visibility from gyro moving fast every seconds
    const stabiliseMessageTimoutID = setTimeout(() => {
      state.showStabiliseMessageInterval = isDeviceMovingFast;
      setState({ showStabiliseMessageInterval: isDeviceMovingFast });
    }, 300);
    return () => clearTimeout(stabiliseMessageTimoutID);
  }, [isDeviceMovingFast]);

  // handle prediction running state if is predicting window or not
  const setPredictionRunning = (isRunning: boolean) => {
    isRunning ? startPrediction() : stopPrediction();
  };

  // --------------------------------------------------------------------------- RENDER

  const classBlockName = classBlock([component, props.classNames]);

  return (
    <div className={classBlockName}>
      <video
        className={`${classBlockName}_video`}
        autoPlay
        playsInline
        muted
        loop
        ref={videoRef}
      />
      <BoxRenderer
        {...{
          model,
          videoRef,
          webcamLoaded
        }}
      />
      <AlertDialog
        alerts={[
          {
            text: locale.stabilityMessage,
            condition: state.showStabiliseMessageInterval
          }
        ]}
      />
    </div>
  );
}

export default CameraTracking;
