import "./TutorialPage.less";
import React, { RefObject } from "react";
import { ReactPage } from "../../lib/solidify/react/ReactPage";
import { Helmet } from "react-helmet";
import { prepare } from "../../helpers/prepare";
import GridBackground, {
  EBackgroundColor
} from "../../components/gridBackground/GridBackground";
import Swipable, { SwipeDirections } from "../../components/swipable/Swipable";
import { Router } from "../../lib/solidify/navigation/Router";
import { EPage } from "../../Main";
import { gsap, Quad } from "gsap/gsap-core";
import Lottie from "lottie-web";
import CameraBackground from "../../components/cameraBackground";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {}
interface IStates {
  currentSlideIndex: number;
  nextPage: EPage;
  anim;
  background: EBackgroundColor;
  nextBackground: EBackgroundColor;
  isBackgroundVisible: boolean;
}
// prepare
const { component, log } = prepare("TutorialPage");

// get text data from local
const locale = GlobalConfig.instance.data.pages.tutorial;
/**
 * @name TutorialPage
 */
class TutorialPage extends ReactPage<IProps, IStates> {
  // --------------------------------------------------------------------------- INIT

  // get root ref
  protected $root: HTMLDivElement;
  protected $slider: HTMLDivElement;
  protected $content: RefObject<HTMLDivElement>;
  protected $video: HTMLVideoElement;
  protected $anim;

  protected _animation;

  constructor(pProps: IProps, pContext: any) {
    super(pProps, pContext);
    this.$content = React.createRef();
  }

  initAnim() {
    //setTimeout(() => {
    //}, 100);
  }

  // --------------------------------------------------------------------------- TRANSITION

  /**
   * Action on this page.
   * Check props.action and props.parameters to show proper content.
   */
  action() {
    // Remove if not used
  }

  /**
   * Play in animation.
   * Call complete handler when animation is done.
   */
  protected playInPromiseHandler(pCompleteHandler: () => void) {
    this._animation = Lottie.loadAnimation({
      container: this.$anim,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.state.anim,
      rendererSettings: {
        preserveAspectRatio: "xMinYMax slice"
      }
    });
    return new Promise(resolve => {
      pCompleteHandler();
      resolve();
    });
  }

  /**
   * Play out animation.
   * Call complete handler when animation is done.
   */
  protected playOutPromiseHandler(pCompleteHandler: () => void) {
    //return PageTransitionHelper.promisePlayOut(this.rootRef, pCompleteHandler);
    // TODO: make it generic in PageTransitionHelper
    return new Promise(resolve => {
      // si pas de ref, exit

      if (!this.$content.current) return;

      this.setState({
        isBackgroundVisible: false
      });
      // basic transition anim to tutorial page
      gsap.set(this.$root, { zIndex: 1 });
      gsap.to(this.$root, 0.3, {
        backgroundColor: "rgba(0, 0, 0, 0)"
      });
      gsap.fromTo(
        this.$content.current,
        {
          x: 0,
          y: 0
        },
        {
          duration: 1,
          x: this.$content.current.clientWidth,
          y: -this.$content.current.clientHeight,
          rotation: -40,
          ease: Quad.easeOut,
          onComplete: () => {
            pCompleteHandler?.();
            resolve();
          }
        }
      );
    });
  }

  // --------------------------------------------------------------------------- RENDER

  render() {
    const currentSlide = locale.slides[this.state.currentSlideIndex];
    const formatedIndex =
      this.state.currentSlideIndex.toString().length == 1
        ? `0${this.state.currentSlideIndex + 1}`
        : (this.state.currentSlideIndex + 1).toString();

    return (
      <div className={component} ref={r => (this.$root = r)}>
        {this.state.nextBackground == EBackgroundColor.CAMERA ? (
          <CameraBackground isVisible={this.state.isBackgroundVisible} />
        ) : (
          <GridBackground
            hasCorner={true}
            isVisible={this.state.isBackgroundVisible}
            color={this.state.nextBackground}
          />
        )}

        <Swipable
          childRef={this.$content}
          direction={SwipeDirections.UPRIGHT}
          tresholdActionPercent={{ x: 20, y: 15 }}
          tresholdAction={() => {
            Router.openURL(Router.generateURL({ page: this.state.nextPage }));
          }}
        >
          <div className={`${component}_content`} ref={this.$content}>
            <GridBackground
              hasCorner={true}
              isVisible={true}
              color={this.state.background}
            />
            <Helmet>
              <title>{component}</title>
            </Helmet>
            <div className={`${component}_container`}>
              <div
                className={`${component}_slider`}
                ref={r => (this.$slider = r)}
              >
                <div className={`${component}_slide`}>
                  <p className={`${component}_number`}>{formatedIndex}</p>
                  <p className={`${component}_title`}>{currentSlide.title}</p>
                  <p className={`${component}_subtitle`}>
                    {currentSlide.subtitle}
                  </p>
                  <div className={`${component}_circle`}>
                    <span
                      ref={r => (this.$anim = r)}
                      className={`${component}_anim ${component}_anim-${this.state.currentSlideIndex}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Swipable>
      </div>
    );
  }
}

export default TutorialPage;
