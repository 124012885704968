import "./HomePage.less";
import React, { RefObject } from "react";
import { prepare } from "../../helpers/prepare";
import { ReactPage } from "../../lib/solidify/react/ReactPage";
import * as tf from "@tensorflow/tfjs";
import AppConfig from "../../data/AppConfig";

import LoadingView from "../../components/loadingView/LoadingView";
import Swipable, { SwipeDirections } from "../../components/swipable/Swipable";
import { Router } from "../../lib/solidify/navigation/Router";
import { EPage } from "../../Main";
import { gsap, Quad } from "gsap";
import GridBackground from "../../components/gridBackground";
import { EBackgroundColor } from "../../components/gridBackground/GridBackground";
import Corner from "../../components/corner";
import Lottie from "lottie-web";
import Anims from "../../data/Anims";
import { GlobalConfig } from "../../data/GlobalConfig";

interface IProps {
  classNames?: string[];
  setCurrentStep?: (step: string) => void;
  currentStep?: string;
}
interface IStates {
  loaded: boolean;
  isBackgroundVisible: boolean;
}

// prepare
const { component, log } = prepare("HomePage");

// get data
const locale = GlobalConfig.instance.data.pages.home;

/**
 * @name HomePage
 */
class HomePage extends ReactPage<IProps, IStates> {
  protected $root: HTMLDivElement;
  protected $content: RefObject<HTMLDivElement>;
  protected $animTitle: HTMLElement;
  protected $animPetals: HTMLElement;

  protected _animationTitle;
  protected _animationPetals;

  constructor(pProps: IProps, pContext: any) {
    super(pProps, pContext);

    this.$content = React.createRef();

    this.state = {
      loaded: false,
      isBackgroundVisible: true
    };
    const model = tf.loadGraphModel(`${AppConfig.modelPath}/model.json`);
    model.then(() => {
      gsap.delayedCall(2, () => this.setState({ loaded: true }));
    });
  }

  /**
   * Action on this page.
   * Check props.action and props.parameters to show proper content.
   */
  action() {
    // Remove if not used
  }

  componentDidMount(): void {}

  protected loadedHandler() {
    setTimeout(() => {
      this._animationTitle = Lottie.loadAnimation({
        container: this.$animTitle,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: Anims.homePageTitle,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax meet"
        }
      });

      this._animationPetals = Lottie.loadAnimation({
        container: this.$animPetals,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: Anims.homePagePetals,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax slice"
        }
      });
    }, 2000);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ): void {
    if (prevState.loaded != this.state.loaded && this.state.loaded) {
      this.loadedHandler();
    }
  }

  /**
   * Play in animation.
   * Call complete handler when animation is done.
   */
  protected playInPromiseHandler(pCompleteHandler: () => void) {
    return new Promise(resolve => {
      pCompleteHandler();
      resolve();
    });
  }

  /**
   * Play out animation.
   * Call complete handler when animation is done.
   */
  protected playOutPromiseHandler(pCompleteHandler: () => void) {
    //return PageTransitionHelper.promisePlayOut(this.rootRef, pCompleteHandler);
    // TODO: make it generic in PageTransitionHelper
    return new Promise(resolve => {
      // si pas de ref, exit

      //if (!this.contentRef.current) return;

      // basic transition anim to tutorial page
      gsap.set(this.$root, { zIndex: 1 });

      this.setState({
        isBackgroundVisible: false
      });

      gsap.to(this.$root, 0.3, {
        backgroundColor: "rgba(0, 0, 0, 0)"
      });
      gsap.fromTo(
        this.$content.current,
        {
          x: 0,
          y: 0
        },
        {
          duration: 1,
          x: this.$content.current.clientWidth,
          y: -this.$content.current.clientHeight,
          rotation: -40,
          ease: Quad.easeOut,
          onComplete: () => {
            pCompleteHandler?.();
            resolve();
          }
        }
      );
    });
  }

  render() {
    return (
      <div className={component} ref={r => (this.$root = r)}>
        <GridBackground
          hasCorner={true}
          isVisible={this.state.isBackgroundVisible}
          color={EBackgroundColor.GOLD}
        />
        <Swipable
          childRef={this.$content}
          direction={SwipeDirections.UPRIGHT}
          tresholdActionPercent={{ x: 20, y: 15 }}
          tresholdAction={() => {
            Router.openURL(Router.generateURL({ page: EPage.TutorialPage1 }));
          }}
        >
          <div className={`${component}_content`} ref={this.$content}>
            <h1 className={`${component}_title`}>{locale.title}</h1>
            <GridBackground isVisible={true} color={EBackgroundColor.BLUE} />
            <Corner
              withAnimatedArrow={true}
              animateArrowDelay={3.2}
              arrowVisible={this.state.loaded}
              text={"Drag to begin"}
            />
            <span
              className={`${component}_animTitle`}
              ref={r => (this.$animTitle = r)}
            />
            <span
              className={`${component}_animPetals`}
              ref={r => (this.$animPetals = r)}
            />
          </div>
        </Swipable>
        <LoadingView isVisible={!this.state.loaded} />
      </div>
    );
  }
}

export default HomePage;
