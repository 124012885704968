import "./LoadingView.less";
import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import { gsap, Quad } from "gsap";
interface IProps {
  classNames?: string[];
  isVisible?: boolean;
}

// prepare
const { component, log } = prepare("LoadingView");

/**
 * @name LoadingView
 */
function LoadingView(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  const [indexAnim, setIndexAnim] = useState(null);

  const $dates = useRef([]);
  const $root = useRef();

  useEffect(() => {
    $dates.current = $dates.current.slice(0, 9);

    setIndexAnim(9);
  }, []);

  useEffect(() => {
    props.isVisible ? animIn() : animOut();
  }, [props.isVisible]);

  useEffect(() => {
    if (props.isVisible) anim();
  }, [indexAnim]);

  // --------------------------------------------------------------------------- ANIM

  const animIn = () => {
    gsap.to($root.current, {
      opacity: 1,
      duration: 0.5
    });
  };

  const animOut = () => {
    gsap.to($root.current, {
      x: 400,
      y: -400,
      delay: 2,
      ease: Quad.easeInOut,
      rotation: -30,
      duration: 0.7
    });
  };

  const anim = () => {
    gsap.set($dates.current[indexAnim], {
      zIndex: 2
    });

    gsap.fromTo(
      $dates.current[indexAnim],
      {
        rotation: 0,
        opacity: 1,
        duration: 1.5,
        ease: Quad.easeInOut
      },
      {
        rotation: -45,
        opacity: 0,
        delay: 0.5,
        onComplete: () => {
          $dates.current.forEach((date, index) => {
            /*if (indexAnim != index) {
              gsap.set(date, {
                opacity: 1,
                rotation: 0,
                zIndex: 0
              });
            }*/
          });

          if (indexAnim <= 1) {
            $dates.current.forEach((date, index) => {
              if (indexAnim != index) {
                gsap.set(date, {
                  opacity: 1,
                  rotation: 0,
                  zIndex: 0
                });
              }
            });
            setIndexAnim($dates.current.length - 1);
          } else {
            setIndexAnim(indexAnim - 1);
          }
        }
      }
    );
  };

  // --------------------------------------------------------------------------- RENDER
  return (
    <div ref={$root} className={classBlock([component, props.classNames])}>
      <Fragment>
        {[24, 22, 17, 15, 12, 10, 9, 6, 5, 3].map((date, i) => {
          return (
            <div
              key={i}
              ref={el => ($dates.current[i] = el)}
              className={`${component}_date ${component}_date-${i}`}
            >
              <span
                className={`${component}_dateBar ${component}_dateBar-top`}
              />
              <p className={`${component}_dateNum`}>{date}</p>
              <span
                className={`${component}_dateBar ${component}_dateBar-bottom`}
              />
            </div>
          );
        })}
      </Fragment>
    </div>
  );
}

export default LoadingView;
