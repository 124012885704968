import { debug } from "webpack";

class CanvasCaptureHelper {
  static captureFromCanvas(pCanvas, pVideo, pWindow) {
    const boundingBox = pWindow.getSquareBoundingBox();

    const ctx = pCanvas.getContext("2d");
    const captureOffsetRatio = 1.35;
    const offsetX = (boundingBox[2] * captureOffsetRatio - boundingBox[2]) / 2;
    const offsetY = (boundingBox[3] * captureOffsetRatio - boundingBox[3]) / 2;

    pCanvas.width = boundingBox[2] * captureOffsetRatio;
    pCanvas.height = boundingBox[3] * captureOffsetRatio;

    ctx.fillRect(0, 0, pVideo.w, pVideo.h);

    ctx.drawImage(
      pVideo,
      boundingBox[0] - offsetX,
      boundingBox[1] - offsetY,
      boundingBox[2] * captureOffsetRatio,
      boundingBox[3] * captureOffsetRatio,
      0,
      0,
      boundingBox[2] * captureOffsetRatio,
      boundingBox[3] * captureOffsetRatio
    );

    return pCanvas.toDataURL("image/jpeg", 0.3);
  }
}

export default CanvasCaptureHelper;
