import "./Swipable.less";
import React, {
  Component,
  ReactChild,
  ReactChildren,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";

// require hammer as global lib

// Directions
export enum SwipeDirections {
  NONE,
  LEFT,
  RIGHT,
  UP,
  DOWN,
  HORIZONTAL,
  VERTICAL,
  ALL,
  UPLEFT,
  UPRIGHT,
  DOWNLEFT,
  DOWNRIGHT
}

// Declare types
type TVector2 = {
  x: number;
  y: number;
};

interface IProps {
  classNames?: string[];
  children: ReactChild;
  childRef: RefObject<HTMLElement>;
  tresholdActionPercent?: TVector2;
  tresholdAction?: (translation: { x: number; y: number }) => void;
  direction?: SwipeDirections;
  onDrag?: (translation: { x: number; y: number }) => void;
  onDragEnd?: () => void;
}

interface IStates {
  isDragging: boolean;
  origin: TVector2;
  translation: TVector2;
  rotation: number;
  thresholdReached;
}

/**
 * @name Swipable
 * Make an element swipable with drag control
 */
function Swipable(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE
  const Hammer =
    typeof window !== "undefined" ? require("hammerjs") : undefined;
  const { component, log } = prepare("Swipable");
  // prepare

  const ORIGIN_POSITION: TVector2 = { x: 0, y: 0 };
  let hammerManager = null;
  let oldDeltaX,
    oldDeltaY: number = 0;

  // Set initial state
  const [state, setState] = useState<IStates>({
    isDragging: false,
    origin: ORIGIN_POSITION,
    translation: ORIGIN_POSITION,
    rotation: 0,
    thresholdReached: false
  });

  /**
   * Handle Hammer drag start and update state
   */
  const handleDragStart = useCallback(({ deltaX, deltaY }) => {
    if (state.thresholdReached) return;
    setState(state => ({
      ...state,
      isDragging: true,
      origin: { x: deltaX, y: deltaY }
    }));
  }, []);

  /**
   * Handle Hammer drag move and update state
   */
  const handleDrag = useCallback(
    e => {
      if (state.thresholdReached) return;

      // Get delta drag
      const { deltaX, deltaY } = e;

      // Check treshold
      if (
        (props.tresholdActionPercent &&
          Math.abs(deltaX) >
            props.childRef.current.clientWidth *
              props.tresholdActionPercent.x *
              0.01) ||
        Math.abs(deltaY) >
          props.childRef.current.clientHeight *
            props.tresholdActionPercent.y *
            0.01
      ) {
        setState(state => ({
          ...state,
          isDragging: false
        }));
        props.tresholdAction({ ...state.translation });
        state.thresholdReached = true;
        return;
      }

      // get transforms from delta with direction type
      const { translation, rotation } = getDragTransform(deltaX, deltaY);

      // Parent callback
      props.onDrag && props.onDrag(translation);

      // Update state
      setState(state => ({
        ...state,
        translation,
        rotation
      }));

      // Update old delta
      oldDeltaX = deltaX;
      oldDeltaY = deltaY;
    },
    [state.origin, props.onDrag]
  );

  /**
   * Handle Hammer drag end and update state
   */
  const handleDragEnd = useCallback(() => {
    setState(state => ({
      ...state,
      isDragging: false,
      thresholdReached: false
    }));

    // Parent callback
    props.onDragEnd && props.onDragEnd();

    // Reset old delta
    oldDeltaX = 0;
    oldDeltaY = 0;
  }, [props.onDragEnd]);

  // --------------------------------------------------------------------------- UTILS

  /**
   * Get translation and rotation from drag delta with direction type
   * @param deltaX
   * @param deltaY
   */
  const getDragTransform = (
    deltaX: number,
    deltaY: number
  ): { translation: TVector2; rotation: number } => {
    // Set direction
    const directionProperty = props.direction
      ? props.direction
      : SwipeDirections.ALL;

    // Init translation
    let translation: TVector2 = { x: 0, y: 0 };

    // delta rotation multiples
    const xMulti = deltaX * 0.04;
    const yMulti = deltaY / 70;
    let rotation = 1;

    // translate and rotate on Y if directionProperty allows it
    if (
      directionProperty === SwipeDirections.ALL ||
      directionProperty === SwipeDirections.VERTICAL ||
      ((directionProperty === SwipeDirections.DOWN ||
        directionProperty === SwipeDirections.DOWNLEFT ||
        directionProperty === SwipeDirections.DOWNRIGHT) &&
        deltaY > 0) ||
      ((directionProperty === SwipeDirections.UP ||
        directionProperty === SwipeDirections.UPLEFT ||
        directionProperty === SwipeDirections.UPRIGHT) &&
        deltaY < 0)
    ) {
      translation.y = deltaY - state.origin.y;
      rotation *= yMulti;
    }

    // translate and rotate on X if directionProperty allows it
    if (
      directionProperty === SwipeDirections.ALL ||
      directionProperty === SwipeDirections.HORIZONTAL ||
      ((directionProperty === SwipeDirections.LEFT ||
        directionProperty === SwipeDirections.UPLEFT ||
        directionProperty === SwipeDirections.DOWNLEFT) &&
        deltaX < 0) ||
      ((directionProperty === SwipeDirections.RIGHT ||
        directionProperty === SwipeDirections.UPRIGHT ||
        directionProperty === SwipeDirections.DOWNRIGHT) &&
        deltaX > 0)
    ) {
      translation.x = deltaX - state.origin.x;
      rotation *= xMulti;
    }

    return { translation, rotation };
  };

  // --------------------------------------------------------------------------- LYFECYCLE

  // load hammer and init events
  useEffect(() => {
    // create a simple instance on our object
    hammerManager = new Hammer(props.childRef.current);
    // add a "PAN" recognizer to it (all directions)
    hammerManager.add(
      new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 })
    );

    // Add drag events
    hammerManager.on("panstart", handleDragStart);
    hammerManager.on("pan", handleDrag);
    hammerManager.on("panend", handleDragEnd);

    return () => {
      // Discard Hammer session on unmount
      hammerManager.stop();
      hammerManager.destroy();
      setState(state => ({
        ...state,
        translation: { x: 0, y: 0 },
        rotation: 0
      }));
    };
  }, []);

  // manage when dragging or not
  useEffect(() => {
    if (state.isDragging || state.thresholdReached) {
      // Do stuff when drag
    } else {
      setState(state => ({
        ...state,
        translation: { x: 0, y: 0 },
        rotation: 0
      }));
    }
  }, [state.isDragging]);

  // --------------------------------------------------------------------------- PRE RENDER

  // apply styles for animation
  const styles = useMemo(
    () => ({
      cursor: state.isDragging ? "-webkit-grabbing" : "-webkit-grab",
      transform: `translate3D(${state.translation.x}px, ${state.translation.y}px, 0) rotate(${state.rotation}deg)`,
      transition:
        state.isDragging || state.thresholdReached
          ? "none"
          : "transform 400ms cubic-bezier(.28,.62,.51,1.03)"
    }),
    [state.isDragging, state.translation]
  );

  // --------------------------------------------------------------------------- RENDER

  return (
    <div className={classBlock([component, props.classNames])} style={styles}>
      {props.children}
    </div>
  );
}

export default Swipable;
