import "./Button.less";
import React, { ReactNode } from "react";
import { classBlock } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";

interface IProps {
  className?: string;
  onClick?: () => void;
  internal?: boolean;
  children?: ReactNode;
}

// prepare
const { component, log } = prepare("Button");

/**
 * @name Button
 * Simple UI button component that can be <button> or <a>
 */
function Button(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE

  // define button tag type
  const ButtonTag = "button";

  // --------------------------------------------------------------------------- RENDER

  return (
    <ButtonTag
      className={`
        ${classBlock([component, props.className])}
       `}
      onClick={props.onClick}
      children={props.children}
    />
  );
}

export default Button;
