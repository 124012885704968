import "./BoxRenderer.less";
import React, { useRef, useEffect, RefObject } from "react";
import { prepare } from "../../helpers/prepare";
import useBoxRenderer from "../../hooks/useBoxRenderer";
import WindowBoxesService from "services/WindowBoxesService/WindowBoxesService";
import CanvasCaptureHelper from "../../helpers/canvasCaptureHelper";
import LocalStorageHelper from "../../helpers/localStorageHelper";
import { classBlock } from "../../helpers/className";
import popinService from "services/popinService";
import Popin from "components/popin/Popin";
import WindowPopin from "components/windowPopin/WindowPopin";

interface IProps {
  classNames?: string[];
  model: object;
  videoRef: RefObject<HTMLVideoElement>;
  webcamLoaded: boolean;
}

// prepare
const { component, log } = prepare("BoxRenderer");

export const BOX_CONTAINER_ID = "box-container-id";

/**
 * @name BoxRenderer
 */

const BoxRenderer = (props: IProps) => {
  // --------------------------------------------------------------------------- PREPARE

  const { model, videoRef, webcamLoaded } = props;
  const canvasRef = useRef();

  const classBlockName = classBlock([component, props.classNames]);

  const windowBoxClickHandler = windowBox => {
    popinService.show(
      <WindowPopin
        canSave={true}
        windowBox={windowBox}
        image={CanvasCaptureHelper.captureFromCanvas(
          canvasRef.current,
          videoRef.current,
          windowBox
        )}
      />
    );
  };

  useEffect(() => {
    WindowBoxesService.on("window-box-click", windowBoxClickHandler);

    return () =>
      WindowBoxesService.off("window-box-click", windowBoxClickHandler);
  }, []);

  // --------------------------------------------------------------------------- RENDER
  return (
    <div className={component}>
      <div id={BOX_CONTAINER_ID} className={`${component}_boxList`}>
        {/* box list */}
      </div>
      <canvas className={`${classBlockName}_canvas`} ref={canvasRef}></canvas>
    </div>
  );
};

export default BoxRenderer;
