import "./PopinContainer.less";
import React, { useEffect, useState, createRef, RefObject } from "react";
import { classBlock, className } from "../../helpers/className";
import { prepare } from "../../helpers/prepare";
import { Router } from "../../lib/solidify/navigation/Router";
import popinService from "services/popinService";

interface IProps {
  classNames?: string[];
}

// Prepare
const { component, log } = prepare("PopinContainer");

/**
 * @name PopinContainer
 */
function PopinContainer(props: IProps) {
  // --------------------------------------------------------------------------- PREPARE RENDER

  const [popinContent, setPopinContent] = useState();

  const rootRef: RefObject<HTMLDivElement> = createRef();

  const onPopinShow = element => {
    setPopinContent(element);
  };

  const onPopinHide = () => {
    setPopinContent(null);
  };

  useEffect(() => {
    popinService.on("show", onPopinShow);
    popinService.on("hide", onPopinHide);

    return () => {
      popinService.off("show", onPopinShow);
      popinService.off("hide", onPopinHide);
    };
  }, []);

  // --------------------------------------------------------------------------- RENDER

  return (
    <div
      className={classBlock([component, props.classNames])}
      style={{
        display: popinContent ? "flex" : "none"
      }}
      onClick={event => event.target === rootRef.current && popinService.hide()}
      ref={rootRef}
    >
      <div className={`${component}_popinContent`}>{popinContent}</div>
    </div>
  );
}

export default PopinContainer;
