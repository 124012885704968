export class LocaleStruct {
  /**
   * Traductions sur le layout
   */
  layout: {
    title: string;
    description: string;
  };

  pages: {
    home: {
      title: string;
    };
    notFound: {
      background: string;
      replay: string;
      title: string;
      text: string;
    };
    tutorial: {
      slides: {
        title: string;
        subtitle: string;
      }[];
    };
    calendar: {
      title: string;
      subtitle: string;
      text: string;
    };
    aboutPopin: {
      title: string;
      paragraphs: [
        {
          title?: string;
          text?: string;
          image?: string;
          video?: string;
        }[]
      ];
    };
  };

  components: {
    footerMenu: {
      calendar: string;
      about: string;
      camera: string;
    };
    desktopScreen: {
      cta: string;
      subtitle: string;
      qrCode: string;
    };
    windowPopin: {
      addToResolutions: string;
      close: string;
    };
  };

  molecules: {};
}
